.flex-rows {
display: flex;
flex-direction: row; 
} 
.flex-row-multicolums{ 
display: flex;
flex-flow: row wrap;
justify-content: center;
}
.flex-cols{
width: 22%;
border: 2px solid #f3f0f0;
background-color: black;
border-radius: 230px 230px 0px 0px;
padding: 70px 10px 10px 10px;
margin: 5px 0 20px 10px;
}
.img_borad{
  width: 100%;
  height: auto;
}
.btn_cen{
display: flex;
justify-content: center;
align-items: center;
}
.para_txt{
  border: 1px solid;
  text-align: center;
  height: 301px;
  overflow: scroll;
  color: grey;
  font-size: 13px;
}
@media only screen and (max-width: 768px) {

.flex-row-multicolums {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 30px;
}
.flex-cols {
  width: 100%;
  border: 2px solid #f3f0f0;
  background-color: black;
  padding: 70px 10px 10px 10px;
  margin: 5px 0 17px 10px;
}
}
