.hero-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    padding: 0 3vw;
}
.hero-section h1{
    margin-bottom: 10px;
}
.hero-section h2{
    font-size: 20px;
    margin-bottom: 20px;
}
.hero-section p{
    margin-bottom: 20px;
    opacity: 80%;
}
@media only screen and (min-width: 1000px){
    .hero-section{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}