.med_main {
  margin: 120px 1000px 0px 0;
}
.med_img {
  border-radius: 35%;
  width: 430px;
  transform: translate(-20%, -8%);
  padding: 0;
  height: 600px;
  margin-left: 200px;
  margin-top: 70px;
}
.med_head {
  margin: 450px 200px 0 200px;
  font-size: 30px;
}

.btn_cen {
  color: yellow;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50px;
  transition: all 0.15s ease-in-out;
  font-weight: bold;
}

.dot {
  width: 30px;
  height: 30px;
  background-color: #d2d3e8;
  border-radius: 15px;
  transform: translate(-720%, 0%);
}

.promo_area {
  height: 1000px;
  position: relative;
}
.main-title {
  position: absolute;
  top: 5%;
  left: 116%;
}
.main-para {
  position: absolute;
  top: 20%;
  left: 850px;
}
.main-title h1 {
  font-size: 48px;
}
.promo_area .round-planet {
  width: 862px;
  height: 862px;
  border: 2px solid rgba(229, 227, 253, 0.502);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
  background-image: -moz-linear-gradient(
    0deg,
    rgba(134, 117, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.502) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(134, 117, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.502) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgba(134, 117, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.502) 100%
  );
}
.promo_area .round-planet.planet {
  top: 51%;
}
.promo_area .round-planet.planet2 {
  width: 750px;
  height: 750px;
}
.promo_area .round-planet.planet3 {
  width: 642px;
  height: 642px;
}
.promo_area .round-planet .star {
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
}
.promo_area .round-planet .star.star1 {
  margin-top: -15px;
  margin-left: -15px;
  width: 10px;
  height: 10px;
  border: 3px solid white;
  padding: 21px;
  background-image: url("https://cafe.thirdwavecoffee.in/3wcoffees/images/footer_coffee-cup.svg");
  animation: spinnerRotate 20s linear infinite;
}
.promo_area .round-planet .star.star2 {
  -webkit-animation: spinnerRotateone 20s linear infinite;
  animation: spinnerRotateone 20s linear infinite;
  width: 10px;
  height: 10px;
  border: 1px solid white;
  padding: 25px;
  background-image: url("../../public/favicons.png");
  /* background: #8675ff; */
  margin-top: -9px;
  margin-left: -9px;
}

.promo_area .round-planet .star.star3 {
  width: 10px;
  height: 10px;
  border: 3px solid white;
  padding: 25px;
  background-image: url("https://cafe.thirdwavecoffee.in/3wcoffees/images/footer_coffee-cup.svg");
  margin-top: -8px;
  margin-left: -8px;
  -webkit-animation: spinnerRotatetwo 20s linear infinite;
  animation: spinnerRotatetwo 20s linear infinite;
  /* background: #d90f5b; */
}

.promo_area .round-planet .star.star4 {
  width: 10px;
  height: 10px;
  border: 1px solid white;
  padding: 25px;
  background-image: url("../../public/favicons.png");
  margin-top: -4px;
  margin-left: -4px;
  -webkit-animation: spinnerRotatethree 20s linear infinite;
  animation: spinnerRotatethree 20s linear infinite;
  /* background: #ffb633; */
}
.promo_area .round-planet .star.star5 {
  -webkit-animation: spinnerRotatefour 25s linear infinite;
  animation: spinnerRotatefour 25s linear infinite;
}

.promo_area .round-planet .star.star6 {
  -webkit-animation: spinnerRotatefive 25s linear infinite;
  animation: spinnerRotatefive 25s linear infinite;
}

.promo_area .round-planet .star.star7 {
  -webkit-animation: spinnerRotatesix 25s linear infinite;
  animation: spinnerRotatesix 25s linear infinite;
}

.promo_area .round-planet .star.star8 {
  -webkit-animation: spinnerRotateseven 25s linear infinite;
  animation: spinnerRotateseven 25s linear infinite;
}


@-webkit-keyframes spinnerRotate {
  0% {
    -webkit-transform: rotate(0deg) translateX(320px);
    transform: rotate(0deg) translateX(320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(320px);
    transform: rotate(360deg) translateX(320px);
  }
}
@keyframes spinnerRotate {
  0% {
    -webkit-transform: rotate(0deg) translateX(320px);
    transform: rotate(0deg) translateX(320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(320px);
    transform: rotate(360deg) translateX(320px);
  }
}

@-webkit-keyframes spinnerRotateone {
  0% {
    -webkit-transform: rotate(0deg) translateY(320px);
    transform: rotate(0deg) translateY(320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(320px);
    transform: rotate(360deg) translateY(320px);
  }
}

@keyframes spinnerRotateone {
  0% {
    -webkit-transform: rotate(0deg) translateY(320px);
    transform: rotate(0deg) translateY(320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(320px);
    transform: rotate(360deg) translateY(320px);
  }
}

@-webkit-keyframes spinnerRotatetwo {
  0% {
    -webkit-transform: rotate(0deg) translateY(-320px);
    transform: rotate(0deg) translateY(-320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(-320px);
    transform: rotate(360deg) translateY(-320px);
  }
}

@keyframes spinnerRotatetwo {
  0% {
    -webkit-transform: rotate(0deg) translateY(-320px);
    transform: rotate(0deg) translateY(-320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(-320px);
    transform: rotate(360deg) translateY(-320px);
  }
}

@-webkit-keyframes spinnerRotatethree {
  0% {
    -webkit-transform: rotate(0deg) translateX(-320px);
    transform: rotate(0deg) translateX(-320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(-320px);
    transform: rotate(360deg) translateX(-320px);
  }
}

@keyframes spinnerRotatethree {
  0% {
    -webkit-transform: rotate(0deg) translateX(-320px);
    transform: rotate(0deg) translateX(-320px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(-320px);
    transform: rotate(360deg) translateX(-320px);
  }
}

@-webkit-keyframes spinnerRotatefour {
  0% {
    -webkit-transform: rotate(0deg) translateX(375px);
    transform: rotate(0deg) translateX(375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(375px);
    transform: rotate(360deg) translateX(375px);
  }
}

@keyframes spinnerRotatefour {
  0% {
    -webkit-transform: rotate(0deg) translateX(375px);
    transform: rotate(0deg) translateX(375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(375px);
    transform: rotate(360deg) translateX(375px);
  }
}

@-webkit-keyframes spinnerRotatefive {
  0% {
    -webkit-transform: rotate(0deg) translateY(375px);
    transform: rotate(0deg) translateY(375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(375px);
    transform: rotate(360deg) translateY(375px);
  }
}

@keyframes spinnerRotatefive {
  0% {
    -webkit-transform: rotate(0deg) translateY(375px);
    transform: rotate(0deg) translateY(375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(375px);
    transform: rotate(360deg) translateY(375px);
  }
}

@-webkit-keyframes spinnerRotatesix {
  0% {
    -webkit-transform: rotate(0deg) translateY(-375px);
    transform: rotate(0deg) translateY(-375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(-375px);
    transform: rotate(360deg) translateY(-375px);
  }
}

@keyframes spinnerRotatesix {
  0% {
    -webkit-transform: rotate(0deg) translateY(-375px);
    transform: rotate(0deg) translateY(-375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(-375px);
    transform: rotate(360deg) translateY(-375px);
  }
}

@-webkit-keyframes spinnerRotateseven {
  0% {
    -webkit-transform: rotate(0deg) translateX(-375px);
    transform: rotate(0deg) translateX(-375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(-375px);
    transform: rotate(360deg) translateX(-375px);
  }
}

@keyframes spinnerRotateseven {
  0% {
    -webkit-transform: rotate(0deg) translateX(-375px);
    transform: rotate(0deg) translateX(-375px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateX(-375px);
    transform: rotate(360deg) translateX(-375px);
  }
}
