.board-game{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3vw;
}
.board-game h1{
    margin-bottom: 10px;
}
.board-game h2{
    font-size: 20px;
    margin-bottom: 20px;
}
.board-game p{
    margin-bottom: 20px;
    opacity: 80%;
}
.view_btn {
  color: white;
  background-color: transparent;
  padding: 20px;
}
.view_btn:hover {
  color: orange;
}
/* Reset some default styles for consistency */
body,
h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
  color: #495057;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.specials {
  padding: 80px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 30px;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  color: #007bff;
}

.section-title p {
  font-size: 18px;
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 2px solid #007bff;
}

.nav-item {
  margin-bottom: 10px;
}

.nav-link {
  font-size: 16px;
  color: #343a40;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link.active {
  background-color: #007bff;
  color: #fff;
}

.tab-pane {
  padding: 20px;
}

.tab-pane h3 {
  font-size: 24px;
  font-weight: 700;
  color: #007bff;
}

.tab-pane p {
  font-size: 16px;
  line-height: 1.6;
  color: #343a40;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
@media only screen and (min-width: 1000px){
    .board-game{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}