.pay{
    background-color: #f9f6f1;
    width: 100%;
    overflow: hidden;
    padding: 20px 3vw;
    min-height: 100vh;
    font-family: "Lexend", sans-serif;    
}
.pay .qr-code, .qr-code img{
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    /* mix-blend-mode: color-dodge; */
}
.qr-code h4{
    margin-bottom: 10px;
}
.pay .qr-code{
    max-width: none;
    margin-top: 30px;
    max-width: 700px;
    color: #333;
}
.payment-ref{
    margin: 10px 0;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.payment-ref input, .payment-ref button{
    height: 40px;
    padding:  0 10px ;
}
.payment-ref button {
    background-color: #E62E28;
    border: 1px solid #333;
    color: #f9f6f1;
    font-weight: 500;
    text-transform: uppercase;
}
.book-info{
    background-color: #fff;
    color: #333;
    margin-top: 30px;
    padding: 80px 20px;
    border-radius: 16px;
    position: relative;
    height: fit-content;
    width: 100%;
    max-width: 700px;
}
.book-date{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    gap: 5px;
}
.book-date h1 {
    display: flex;
    flex-direction: column;
    font-size: 22px;
}
.book-date h1 span{
    font-size: 12px;
    font-weight: 500;
}
.book-date h3{
    font-weight: 400;
    font-size: 14px;
}
.book-date h2{
    font-size: 18px;
}
.user-info, .more-info{
    width: 100%;
}
.user-info h3, .more-info h3{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 3px;
}
.user-info h3 span{
    font-weight: 700;
}
.more-info{
    font-size: 14px;
    font-weight: 400;
}
.circle1, .circle2{
    width: 60px;
    height: 60px;
    background-color: #f9f6f1;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.circle1{
    top: -30px;
}
.circle2{
    bottom: -30px;
}
@media only screen and (min-width: 700px){
    .pay{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

}