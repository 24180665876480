.book-slot{
    background-color: #f9f6f1;
    min-height: 100vh;
    width: 100%;
    color: #333;
    padding: 30px 2vw;
    position: relative;
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
}
.book-slot > div {
    margin-bottom: 60px ;
}
.slot-date{
    height: fit-content;
}
.date-picker{
    border: 1px solid #333;
    border-radius: 20px;
    padding: 10px;
}
.theatre-select{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-y: scroll;
    overflow-x: visible;
    gap: 10px;
    position: relative;
}
.theatre{
    position: relative;
    display: grid;
    grid-template-rows: 200px auto;
    width: 100%;
    min-width: 250px;
    height: 100%;
    min-height: 370px;
    border: 1px solid rgba(51, 51, 51, .1);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px inset;
    border-radius: 18px;
}
.selected-theatre{
    border: 2px solid #E62E28;
}
.selected-theatre::after{
    content: 'Selected';
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 8px;
    color: #f9f6f1;
    background-color: #E62E28;
    font-size: 12px;
    border-radius: 18px;
}
.theatre-image{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.theatre-image img{
    padding: 5px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 50px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}
.theatre-image a {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #f9f6f1;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2px;
    font-size: 8px;
}
.theatre-info{
    padding: 10px;
}
.theatre-info > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}
.theatre-info h2{
    font-size: 18px;
}
.theatre-info h3{
    font-size: 16px;
    font-weight: 500;
}
.theatre-info p {
    font-size: 12px;
    opacity: 80%;
}
.slot-duration-wrapper{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.slot-durations{
    display: grid;
    grid-template-columns: repeat(3, 80px);
    gap: 5px;
}
.slot-duration{
     padding: 8px;
    border: 1px solid #333;
    font-size: 12px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}
.slots-time-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
    margin-top: 20px;
}
.slot-time{
    padding: 8px;
    border: 1px solid #333;
    font-size: 12px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}
.slots-times h3 span, .slots-duration h3 span{
    font-weight: 400;
    font-size: 14px;
}
.selected-slot-time, .selected-slot-duration{
    background-color: #E62E28;
    color: #f9f6f1;
}
.slot-unavailable, .slot-unavailable{
    opacity: 40%;
    cursor: not-allowed;
}
.guests{
    margin-top: 30px;
}
.guests > p{
    opacity: 60%;
    font-size: 12px;
}
.guest-counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin-top: 10px;
}
.counter-label {
  font-weight: bold;
  margin-bottom: 8px;
}
.counter-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.decrement-btn,
.increment-btn {
  background-color: #E62E28;
  color: white;
  border: 1px solid #333;
  height: 30px;
  width: 30px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.decrement-btn:disabled, .increment-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.guest-count {
  font-size: 20px;
  font-weight: bold;
  margin: 0 16px;
  height: 30px;
  width: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #333;
}
.decorations, .cakes{
    margin-top: 30px;
}
.decoration-cards, .cake-cards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    margin-top: 20px;
    gap: 10px;
}
.decoration-card, .cake-card{
    border: 1px solid #333;
    padding: 10px;
    border-radius: 20px;
    min-height: 150px;
    cursor: pointer;
    max-width: 300px;
}
.decoration-card h3, .cake-card h3{
    font-size: 20px;
    text-transform: capitalize;
}
.decoration-card h4, .cake-card h4{
    font-weight: 400;
}
.decoration-card:hover, .cake-card:hover{
    background-color: #e0605c;
}
.selected-card{
    background-color: #E62E28;
}
.user-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 30px 0;
}
.book-slot .user-info input{
    width: 100%;
    background: none;
    border: 1px solid #333;
    height: 35px;
    font-size: 18px;
    padding: 0 10px;
    margin: 10px 0;
    border-radius: 32px;
}
.user-info p{
    font-size: 12px;
    color: #E62E28;
    font-weight: 300;
}
.user-info button {
   background-color: #E62E28;
   height: 40px;
   border: 1px solid #333;
   font-family: 'Lexend' sans-serif;
   font-weight: 800;
   font-size: 16px;
   text-transform: uppercase;
   color: #f9f6f1;
   border-radius: 32px;
   cursor: pointer;
}
.user-info button:disabled {
    opacity: 50%;
    cursor: not-allowed;
}
.phone-input-wrapper{
    display: grid;
    grid-template-columns: auto 80px;
    gap: 10px;
    align-items: center;
}
.phone-input-wrapper h3{
    padding: 0 5px;
}
.phone-input-wrapper button{
    height: 35px;
    font-size: 14px;
}
.price-box{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 20px;
}
.price-box .theatre-location{
    display: flex;
    gap: 4px;
}
.price-box .theatre-location a{
    color: #E62E28;
    font-size: 20px;
}
.unavailable-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    color: #f9f6f1;
    z-index: 4;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}
.unavailable-overlay > img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 18px;
    object-fit: cover;
    filter: brightness(60%);
}
.unavailable-overlay p, .unavailable-overlay svg{
    z-index: 5;
    font-weight: 600;
}
.theatre:has(.unavailable-overlay){
    cursor: not-allowed;
    pointer-events: none;
}
.errors{
    background-color: #f6ead6;
    border-radius: 16px;
}
.errors p{
    font-size: 16px;
    padding: 6px 8px;

}
@media only screen and (min-width: 1000px){
    .book-slot{
        display: grid;
        grid-template-columns: 1fr 1.2fr;
        gap: 20px;
        padding: 50px;
    }
}