.our_main {
  border: 5px solid red;
  padding: 20px;
  text-align: left;
  overflow: hidden;
}
.ourcomponets{
  padding: 50px;
  font-size: 25px;
  text-align: left;
  color: white;
}
.newcomponets {
  padding: 50px;
  font-size: 25px;
  text-align: left;
  color: white;
  background-color: black;
  margin-top: 250px;
}
.newcomponets .article_group {
  width: 100%;
  border-radius: 5px;
  height: 1000px;
  margin-bottom: 30px;
}
.our_head {
  color: white;
  text-align: left;
  font-size: 43px;
  margin: 100px 0 0 50px;
}
