@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.App{
  color: #f8f9fa;
    background-color: #f8f9fa;

}
.home{
  background-color: #181818;
  width: 100%;
  overflow: hidden;
}
.layout{
  min-height: 90vh;
  padding-top: 50px;
  margin: 0 auto;
}
.btns{
  background-color: orange;
  color: black;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
}
.txt-center{
  text-align: center;
}
.margin_lft{
margin-left: 50px;
}
.margin_tp{
  margin-top: 200px;
}
.margin_rgt{
  margin-right: 50px;
}
.margin_btm{
  margin-bottom: 50px;
}
.font_hd{
  font-size: 25px;
  font-weight: 600;
}
.margin{
  margin: 150px 0 30px 30px;
}
.d-flex{
  display: flex;
}
.image_home{
  border-radius: 70px;
  width: 700px;
  opacity: 1.2;
  margin-top: 120px;
}
.txt-light{
  color: white;
}
.txt-grey{
  color: grey;
}
.hide_tab{
  display: none;
}
.overflow-hidden {
  overflow: hidden;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

.overflow-hidden-y {
  overflow-y: hidden;
}
@font-face {
  font-family: 'Bruce Forever';
  src: local('Bruce Forever');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Bruce Forever', sans-serif;
}
