body {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* font-family: "Poppins", sans-serif; */
  background-color: black;
}

.contact.containers {
  display: flex;
  justify-content: space-between;
}

.form-txt {
  flex: 1;
  margin-right: 20px;
}

.img_mapp {
  flex: 1;
  margin:100;
}
.img_home{
  flex: 1 1;
  margin: 200px 40px 150px 40px;
}
.cont_main {
  margin: 200px 0 0 20px;
    a, u {
    text-decoration: none;
    color: orange;
  }
}
