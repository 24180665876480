
.row-multicolums {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row-cols {
  width: 48%;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-cols {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pictures-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 50%;
  border-radius: 8px;
}
.banner-img {
  width: 100%;
  height: 600px;

  filter: blur(8px);

}
.para-price {
  font-size: 50px;
  color: #e4ca42;
  text-align: center;
}
.para-head {
  font-size: 20px;
  margin-top: 21px;
  color: white;
  text-align: center;
}

.para-main {
  font-size: 16px;
  margin-top: 10px;
  color: gray;
  line-height: 23px;
}
.para-main1 {
  font-size: 16px;
  margin-top: 10px;
  color: white;
  font-weight: bold;
  line-height: 23px;
}
.para-main2 {
  font-size: 16px;
  margin-top: 80px;
  color: white;
  font-weight: bold;
  line-height: 23px;
}

.table_row {
  display: flex;
}

.table_row .row-cols {
  width: 50%;
  border: 2px solid #5e5151;
  background-color: black;
  padding: 20px 5px 5px 5px;
}

.read-more-button {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.read-more-button:hover {
  background-color: #0056b3;
}
.block {
  display: block;
  width: 24%;
  border: none;
  background-color: yellow;
  padding: 17px 21px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin: 0 210px auto;
  border-radius: 10px;
  margin-bottom: 40px;
}

.block:hover {
  background-color: #ddd;
  color: black;
}
.container_img {
  position: relative;
  text-align: center;
  color: white;
}
.centeres {
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  line-height: 40px;
  font-size: 26px;
}
.centeress {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

  font-size: 31px;
}

.centered {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: bold;
  font-size: 40px;
}
.foter_img {
  /* position: relative; */
  text-align: center;
  color: white;
}
.fots-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 500px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
}
/* Media query for mobile */
@media (max-width: 768px) {
  .table-cols {
    width: 100%; /* Full width for mobile */
  }
  .pictures-img {
    width: 80%;
    max-width: 80%;
    height: auto;
    border-radius: 8px;
  }
}
