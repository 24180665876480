.mini-theatre{
  background-color: #f9f6f1;
  min-height: 100vh;
  width: 100%;
  color: #333;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
}
.mini-theatre p {
  opacity: 80%;
}
.mini-theatre > div {
  padding: 0 4vw;
}
.mini-theatre .hero-section .hero-title h1{
  margin-bottom: 8px;
}
.mini-theatre .hero-section .hero-title p{
  opacity: 80%;
}
.mini-theatre .hero-section .hero-title span{
  color: #E62E28;
}
.hero-title {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}
.mini-theatre .hero-section{
  min-height: calc(100svh - 80px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
  position: relative;
}
.mini-theatre .hero-section .hero-image-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.mini-theatre .hero-section .hero-image{
  height: 100%;
  width: 100%;
  max-width: 500px;
 transform: translateX(5vw);
}

.mini-theatre .hero-section .hero-image > img{
  height: 100%;
  width: 100%;
  min-height: 350px;
  object-fit: cover;
  -webkit-mask-image: url(#mask);
  mask-image: url(#mask);
}
.mini-theatre .hero-button{
  align-self: flex-start;
  padding: 10px 15px;
  border: none;
  background-color: #E62E28;
  color: #f9f6f1;
  font-weight: 700;
  font-size: 22px;
  text-decoration: none;
}
.mini-theatre .theatres{
  background-color: #E62E28;
  position: relative;
  overflow: hidden;
  color: #f9f6f1;
  padding: 20px 10px;
}
.mini-theatre .theatres button{
  align-items: center;
  width: 100px;
  display: block;
  padding: 15px 0;
  background-color: #f9f6f1;
  border: none;
  border-radius: 12px;
}
.mini-theatre .theatres .hero-button{
   align-items: center;
  display: block;
  padding: 10px 15px;
  background-color: #f9f6f1;
  border: none;
  font-size: 20px;
  color: #E62E28;
}
.mini-theatre .theatres-title{
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mini-theatre .theatre-image{
  width: 100%;
  height: 100%;
}
.mini-theatre .theatre-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mini-theatre ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.mini-theatre ul li{
  padding: 10px;
  background-color: #e23c36;
  border-radius: 12px;
}
.mini-theatre .features{
  padding: 0px 5vw;
  margin: 50px 0;
}
.mini-theatre .features > h2{
  margin-bottom: 20px;
}
.mini-theatre .features > .cards{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 20px;
}
.mini-theatre .features > .cards > .card{
  padding: 15px;
  min-height: 200px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.mini-theatre .features > .cards > .card:nth-child(1){
    background-color: #d9eada;
    background-color: #E62E28;
    color: #f9f6f1;
}
.mini-theatre .features > .cards > .card:nth-child(1) svg path{
  stroke: #ffffff;
}
.mini-theatre .features > .cards > .card h3{
  font-size: 16px;
}
.mini-theatre .features > .cards > .card p{
  font-size: 14px;
  opacity: 80%;
}
.mini-theatre .features > .cards > .card svg{
  width: 30px;
  height: 30px;
}
.img_map {
    position: relative;
    width: 100%;
    height: 500px;
}
.img_map iframe {
    width: 100%;
    height: 100%;
}
.mini-theatre .location{
  text-align: center;
}
.mini-theatre .location h1{
  margin-bottom: 20px;
}
.mini-theatre .location .hero-button{
  margin: 20px 0;
  padding: 5px 10px;
  background-color: #E62E28;
  border-radius: 6px;
  border: none;
  color: #f9f6f1;
  font-size: 18px;
  font-family: 500;
  margin-bottom: 10px;
}
.mini-theatre .location .img_map{
  margin: 20px 0;
}
@media only screen and (min-width: 767px) {
  .mini-theatre .hero-section.hero-section{
    min-height: 400px;
    max-height: 500px;
    grid-template-columns: 1fr 1fr;
  }
  .theatres{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 30px;
    padding: 30px ;
  }
  .theatre-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .mini-theatre .theatres{
    padding-right: 0;
  }
}