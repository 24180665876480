.movie-home {
  padding: 20px;
  margin-top: 50px;
}
.movie-home h1 {
  margin-bottom: 20px;
  text-align: center;
}

.movie-home p {
  margin-bottom: 20px;
  opacity: 90%;
  text-align: center;
}

.row-multicolums {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.movie_para {
  font-size: 16px;
  margin-top: 10px;
  color: #e4ca42;
  text-align: center;
}

.para-main {
  font-size: 14px;
  margin-top: 10px;
  color: gray;
  line-height: 23px;
}

.row-multicolums {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.row-multicolums .row-cols {
  width: 50%;
  border: 2px solid #5e5151;
  background-color: black;
  padding: 10px 5px 0 5px;
}

.img_movie {
  border-radius: 8px;
  width: 50%;
  height: 50%;
}
.main_txt {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  font-size: 30px;
  text-align: center;
}
.margin_btm {
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {

.row-multicolums .row-cols {
  width: 100%;
  border: 2px solid #5e5151;
  background-color: black;
  padding: 18px 5px 0 20px;
}
.img_movie {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
}