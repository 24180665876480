.horizontal-calendar.react-datepicker,.horizontal-calendar .react-datepicker__month-container{
    width: 100%;
    background: none;
    border: none;
}
.react-datepicker__week, .react-datepicker__day-names{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}
.react-datepicker__day, .react-datepicker__day:hover{
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
}
.horizontal-calendar .react-datepicker__header {
    background-color: transparent;
    text-align: left;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
}
.react-datepicker__current-month{
    padding: 0 10px;
    font-size: 20px;
}
.react-datepicker__navigation{
    left: calc(100% - 80px);
    background-color: #E62E28;
    border-radius: 50%;
    border: 1px solid #333;
}
.react-datepicker__month{
    margin: 0;
}
.react-datepicker__navigation--next{
    left: calc(100% - 40px);
}
.react-datepicker__navigation-icon::before {
    border-color: rgb(255, 255, 255);
}
.react-datepicker__day--selected{
    background-color: #E62E28;
    border: 1px solid #333;
}
.react-datepicker__day--keyboard-selected{
    background-color: rgba(230, 46, 40, .0);
}
.react-datepicker__day:hover{
    background-color: #E62E28;
}
 .react-datepicker__day--outside-month{
    opacity: 20%;
}
/* .react-datepicker__current-month, .react-datepicker__day-names, .react-datepicker__navigation{
    display: none;
} */
@media only screen and (min-width: 1000px){
    .react-datepicker__day:hover{
        background-color: rgba(230, 46, 40, .4);
    }

}