

:root {
  --nav-bg: #f9f6f1;
  --main-clr: #C6A178;
  --nav-shadow: 0px 1px var(--main-clr);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--nav-bg);
  box-shadow: rgba(198, 161, 120, .4) 0px 4px 12px;
  color: #333;
  z-index:10;
  padding: 10px 5vw;
  height: 50px;
}
.logo-name{
  width: 30%;
  max-width: 180px;
  min-width: 100px;
  height: 100%;
}
.logo-name  svg {
  width: 100%;
  height: 100%;
}
.logo-container{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.nav-items.active{
  display: flex;
  flex-direction: column;
  background-color: rgba(230, 46, 40, .9);
  backdrop-filter: blur(10px);
  list-style: none;
  height: 100vh;
  width: 70%;
  position: absolute;
  top: 0;
  right: 0;
  gap: 1px;
  padding: 20px;
  padding-top: 100px;
}
.nav-items{
  display: none;
}
.nav-logo{
  position: absolute;
  top: 0;
  right: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 80px;
}
.nav-logo svg:first-child{
  height: 100%;
  width: auto;
}
.nav-items > li {
  padding: 10px;
}
.nav-items > li a{
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}
.ham-burger{
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (min-width: 767px){
  .ham-burger{
    display: none;
  }
  .nav-items{
    position: static;
    display: flex;
    width: 46%;
    justify-content: space-between;
    gap: 10px;
    list-style: none;
    color: #333;
    font-size: 12px;
  }
  .nav-items > li{
    padding: 0;
  }
  .nav-items > li a{
    text-decoration: none;
    color: #333;
    font-size: 1vw;
    font-weight: 200;
  }
  .nav-logo{
    display: none;
  }
}