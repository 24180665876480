.footer {
  background: #000;
  font-family: "Play", sans-serif;
  text-align: center;
}

.footer .row {
  width: 100%;
  margin: 1% 0%;
  padding: 0.6% 0%;
  color: white;
  /* color:gray;
font-size:0.8em; */
}

.footer .row a {
  text-decoration: none;
  color: gray;
  transition: 0.5s;
}

.footer .row a:hover {
  color: #ba0b0b;
}



.footer .row ul li {
  display: inline-block;
  margin: 0px 30px;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
}
.about-us {
  color: #561b1b;
}
.f_name {
  display: flex;
}
@media (max-width: 720px) {
  .footer {
    text-align: left;
    padding: 5%;
  }
  .footer .row ul li {
    display: block;
    margin: 10px 0px;
    text-align: left;
  }
  .footer .row a i {
    margin: 0% 3%;
  }
}
.link {
  float: right;
  cursor: pointer;
  width: 50px;
  transform: translate(-56px, -180px);
  border-radius: 60px;
  background-color: #ba0b0b;
  height: 50px;
}
.wt_img {
  width: 100px;
  margin: 20px 0 0 -50px;
}
@keyframes effect {
  20%,
  100% {
    width: 50px;
    height: 50px;
    font-size: 30px;
  }
  0%,
  10% {
    width: 55px;
    height: 55px;
    font-size: 35px;
  }
  5% {
    width: 50px;
    height: 50px;
    font-size: 30px;
  }
}
